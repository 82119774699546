<template>
    <div class="e-table-product animation-opacity">
        <table class="e-table-product__table">
            <thead>
                <tr>
                    <th>
                        <h1 class="app-title-dark text-left">Producto</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Código Barra</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">UBIC</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Laboratorio</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Precio</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Cantidad</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-center">----</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(product, index) in products"
                    :key="index"
                >
                    <td>
                        <text-highlight
                            class="app-subtitle"
                            :queries="search"
                        >
                            {{product.product}}
                        </text-highlight>
                    </td>
                    <td>
                        <text-highlight
                            class="app-subtitle"
                            :queries="search"
                        >
                            {{product.barcore}}
                        </text-highlight>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{product.location}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{product.laboratory.laboratory}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{product.sale_price}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{product.quantity}}
                        </h1>
                    </td>
                    <td class="d-flex justify-center flex-wrap">
                        <OBtnToolTipTableComp
                            :smsToolTip="`Añadir a: ${product.product}`"
                            icon="mdi-check"
                            @click="$emit('clickadd', product)"
                        />
                    </td>
                </tr>
                <tr v-if="products.length === 0">
                    <td colspan="7">
                        <h1 class="app-subtitle text-center">
                            SIN INFORMACIÓN
                        </h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import TextHighlight from "vue-text-highlight";
import { OBtnToolTipTableComp } from "../components/organisms";
export default {
    components: {
        OBtnToolTipTableComp,
        TextHighlight
    },
    props: {
        products: Array,
        search: {
            type: String,
            default: ''
        }
    }
}
</script>