<template>
    <div>
        <!--<div class="pa-2">
            <OSearchHeaderComp
                label="Producto"
                v-model="search"
                class="mr-1"
                @entersearch="eSearchProducts()"
            />
        </div>-->
        <div class="d-flex pr-2">
            <div style="width: 100%">
                <div class="pa-2" style="max-width: 305px">
                    <MTextFieldComp
                        label="Porcentaje"
                        v-model="porcentaje"
                        :hideDetails="true"
                        :rules="[
                            value => (`${value}`).length<=100 || 'La composición, no debe exceder los 100 dígitos'
                        ]"
                    />
                </div>
                <div class="pa-2">
                    <v-btn color="green" small outlined @click="clearForm()">LIMPIAR FORM</v-btn>
                    <v-btn class="ml-2" color="green" small outlined @click="buscarProducto()">Buscar Producto</v-btn>
                    <v-btn class="ml-2" color="green" small outlined @click="refreshLaboratories()">
                        Refrescar Laboratorio
                    </v-btn>
                </div>
                <div>
                    <TablaMigration
                        class="mb-2"
                        :products="products"
                        :search="searchFilter"
                        @clickadd="eOpenFormInventory"
                    />
                </div>
            </div>
            <div
                style="height:480px; overflow:auto; min-width:340px;"
                class="pb-2 pt-2"
            >
                <Formulario
                    :dataProduct="dataProduct"
                    :dataLot="dataLot"
                    :mobile="true"
                    :indexProduct="indexProduct"
                    :typesProducts="typesProducts"
                    :isNewTypeProduct="isNewTypeProduct"
                    :laboratoriesList="laboratoriesList"
                    :isNewLaboratory="isNewLaboratory"
                    :dataTypeProduct="dataTypeProduct"
                    :dataLaboratory="dataLaboratory"
                    ref="formproductcomp"
                    @buscarProducto="buscarProducto()"
                    @saveform="eConfirmForm"
                    @clicklaboratoryform="dialogLaboratories=true"
                    @clicktypeproduct="dialogTypeProduct=true"
                    @clickdeletetypeproduct="eDeleteNewTypeProduct()"
                    @clicknewlaboratory="dialogFormLaboratory=true"
                    @clickdeletelaboratory="eDeleteNewLaboratory()"
                    @calcularporcentaje="calcularPorcentaje()"
                />
            </div>
        </div>
        <EDialogFormTypeProductComp
            :dialogTypeProduct="dialogTypeProduct"
            :dataTypeProduct="dataTypeProduct"
            :mobile="false"
            ref="dialogformtypeproduct"
            @clickselect="eSelectTypeProduct"
            @clickclose="eCloseFormTypeProduct"
        />
        <EDialogFormLaboratoryComp
            :dialogLaboratory="dialogFormLaboratory"
            :dataLaboratory="dataLaboratory"
            :mobile="false"
            ref="dialogformlaboratory"
            @clickselect="eSelectFormLaboratory"
            @clickclose="eCloseFormLaboratory"
        />
        <DialogFormInventory
            ref="forminventorycomp"
            :value="dialogFormInventory"
            :dataLot="dataLotAdd"
            @clickclose="eCloseFormInventory()"
            @saveform="eConfirmAddInventory"
        />
        <OSmsConfirmationComp
            :value="dialogConfirm"
            :smsConfirm="smsConfirm"
            :mobile="false"
            @clickno="dialogConfirm = false"
            @clickyes="ePerformDatabaseAction()"
        />
        <OOperationLoaderComp
            smsLoaderOperation="Realizando Operación"
            :value="dialogLoaderOperation"
        />
    </div>
</template>
<script>
import {
    MTextFieldHeaderComp,
    MTextFieldComp
} from "../../components/molecules";
import {
    OSearchHeaderComp,
    OBtnToolTipHeaderComp,
    OLoaderDataComp,
    OSmsConfirmationComp,
    OOperationLoaderComp
} from "../../components/organisms";
import {
    EDialogFormLaboratoryComp,
    EDialogFormTypeProductComp
} from "../../components/ecosystems";
import {
    TablaMigration,
    Formulario,
    DialogFormInventory
} from "../../components_migration";
import {
    Product,
    TypeProduct,
    Laboratory
} from "../../models";
import {
    rulesMixin,
    datesMixin
} from "../../mixins";
export default {
    mixins: [rulesMixin, datesMixin],
    components: {
        OSearchHeaderComp,
        OBtnToolTipHeaderComp,
        TablaMigration,
        MTextFieldHeaderComp,
        OLoaderDataComp,
        Formulario,
        EDialogFormLaboratoryComp,
        EDialogFormTypeProductComp,
        OSmsConfirmationComp,
        OOperationLoaderComp,
        DialogFormInventory,
        MTextFieldComp
    },
    data: () => ({
        dialogLoaderOperation: false,
        dialogConfirm: false,
        dialogLoaderData: false,
        smsConfirm: [],
        search: "",
        products: [],
        indexProduct: -1,
        dataProduct: new Product,
        isNewTypeProduct: false,
        isNewLaboratory: false,
        dataTypeProduct: new TypeProduct,
        dialogTypeProduct: false,
        dataLaboratory: new Laboratory,
        dialogFormLaboratory: false,
        dialogLaboratories: false,
        laboratoriesList: [],
        typesProducts: [],
        numberOperation: 0,
        dataLot: {
            quantity: null,
            expiration_date: null,
            purchase_price: null,
            id_purchase: 0
        },
        product: {},
        dialogFormInventory: false,
        dataLotAdd: {
            quantity: null,
            expiration_date: null,
            id_purchase: 0,
            fk_product: null,
        },
        productsOld: [],
        porcentaje: 10
    }),
    computed: {
        searchFilter: function() {
            if (this.mRuleEmptyField(this.search)) return "";
            return this.search;
        }
    },
    methods: {
        calcularPorcentaje() {
            if (this.mRuleEmptyField(this.porcentaje) ||
                !this.mRuleNumberDecimal(this.porcentaje))
                    this.porcentaje = "0";
            if (!this.mRuleEmptyField(this.dataProduct.sale_price) &&
            this.mRuleNumberDecimal(this.dataProduct.sale_price)) {
                const valorPorcentaje = (parseFloat(this.dataProduct.sale_price) * parseFloat(this.porcentaje)) / 100;
                this.dataLot.purchase_price = parseFloat(this.dataProduct.sale_price) - valorPorcentaje;
            } else this.dataLot.purchase_price = "0";
        },
        async buscarProducto() {
            if (this.mRuleEmptyField(this.dataProduct.barcore)) {
                if (this.mRuleEmptyField(this.dataProduct.product))
                    this.search = "";
                else this.search = this.dataProduct.product;
            } else this.search = this.dataProduct.barcore;
            try {
                const response = await Product.listMigration(this.search);
                this.products = response;
            } catch(error) {
                console.log(error);
                alert('revise error');
            }
        },
        eSelectTypeProduct(isValidForm) {
            if (isValidForm) {
                this.dataProduct.fk_type_product = 0;
                this.isNewTypeProduct = true;
                this.dialogTypeProduct = false;
            } else alert("Corrija los datos en rojo");
        },
        eCloseFormTypeProduct() {
            this.dataTypeProduct = new TypeProduct;
            this.$refs.dialogformtypeproduct.resetForm();
            this.dialogTypeProduct = false;
        },
        eSelectFormLaboratory(isValidForm) {
            if (isValidForm) {
                this.isNewLaboratory = true;
                this.dataProduct.fk_laboratory = 0;
                this.dialogFormLaboratory = false;
            } else alert("Corrija los datos en rojo");
        },
        eCloseFormLaboratory() {
            this.dataLaboratory = new Laboratory;
            this.$refs.dialogformlaboratory.resetForm();
            this.dialogFormLaboratory = false;
        },
        eConfirmForm(isValidForm) {
            this.smsConfirm = this.indexProduct === -1
                ? [`¿Está seguro(a) de registrar: ${this.dataProduct.product}?`]
                : [`¿Está seguro(a) de editar: ${this.dataProduct.product}?`];
            
            if (!isValidForm) alert('error de formulario corrija porfavor');
            else this.dialogConfirm = true;
        },
        eBackTableList() {
            this.isNewTypeProduct = false;
            this.isNewLaboratory = false;
            this.dialogLoaderOperation = false;
            this.dataLot = {
                quantity: null,
                expiration_date: null,
                purchase_price: null,
                id_purchase: 0
            }
            this.dataProduct = new Product();
            this.$refs.formproductcomp.resetForm();
            this.indexProduct = -1;
        },
        async addProduct() {
            const parameters = {
                'inventory': this.dataLot,
                'product': this.dataProduct,
                'type_product': this.dataTypeProduct,
                'new_type_product': this.isNewTypeProduct,
                'laboratory': this.dataLaboratory,
                'new_laboratory': this.isNewLaboratory
            };
            try {
                const response = await Product.addMigrate(parameters);
                this.laboratoriesList = response.laboratories,
                this.products = [response.product];
                if (response.type_product.id != undefined)
                    this.typesProducts.push(response.type_product);
                /*if (response.laboratory.id != undefined)
                    this.laboratoriesList.push(response.laboratory);*/
                this.eBackTableList();
            } catch (error) {
                console.log(error);
                this.dialogLoaderOperation = false;
                alert('ocurrio un error');
            }
        },
        async addLotProduct() {
            this.dataLotAdd.fk_product = this.product.id;
            try {
                const response = await Product.addLotMigrate(this.dataLotAdd);
                const index = this.products.indexOf(this.product);
                Object.assign(this.products[index], response.product);
                this.laboratoriesList = response.laboratories;
                this.eCloseFormInventory();
                this.numberOperation = 0;
                this.dialogLoaderOperation = false;
            } catch (error) {
                console.log(error);
                alert('ocurrio un error');
            }
        },
        ePerformDatabaseAction() {
            this.dialogConfirm = false;
            this.dialogLoaderOperation = true;
            switch(this.numberOperation) {
                case 0:
                    this.addProduct();
                    break;
                case 1:
                    this.addLotProduct();
                    break;
            }
        },
        eDeleteNewTypeProduct() {
            this.dataProduct.fk_type_product = null;
            this.dataTypeProduct = new TypeProduct;
            this.$refs.dialogformtypeproduct.resetForm();
            this.isNewTypeProduct = false;
        },
        eDeleteNewLaboratory() {
            this.dataLaboratory = new Laboratory;
            this.$refs.dialogformlaboratory.resetForm();
            this.dataProduct.fk_laboratory = null;
            this.isNewLaboratory = false;
        },
        async getRequest() {
            try {
                const typeProducts = Product.typeProductsMigrate();
                const laboratories = Product.laboratoriosMigrate();
                const responses = await Promise.all([typeProducts, laboratories]);
                //const responses = await Promise.all([typeProducts]);
                this.typesProducts = responses[0];
                this.laboratoriesList = responses[1];
            } catch(error) {
                console.log(error);
                alert('error en los datos');
            }
        },
        eOpenFormInventory(product) {
            this.numberOperation = 1;
            this.product = product;
            this.dialogFormInventory = true;
        },
        eCloseFormInventory() {
            this.dataLotAdd = {
                quantity: null,
                expiration_date: null,
                id_purchase: 0,
                fk_product: null
            }
            this.$refs.forminventorycomp.resetForm();
            this.dialogFormInventory = false;
        },
        eConfirmAddInventory(isValidForm) {
            if (!isValidForm) alert('corrija los datos en rojo');
            else {
                this.dialogFormInventory = false;
                this.smsConfirm = ['Esta seguro de añadir',
                    `Producto: ${this.product.product}`,
                    `Cantidad: ${this.dataLotAdd.quantity}`,
                    `Fecha expiración: ${this.mDateFormat(this.dataLotAdd.expiration_date)}`];
                this.dialogConfirm = true;
            }
        },
        addOldProduct(product) {
            const [numberPrice, decimalPrice] = product.precio.split(',');
            this.dataProduct.sale_price = decimalPrice != undefined
                ? `${numberPrice}.${decimalPrice}`
                : `${numberPrice}`;
            const [number, decimal] = product.costo.split(',');
            this.dataLot.purchase_price = decimal != undefined
                ? `${number}.${decimal}`
                : `${number}`;
            this.dataProduct.composition = this.mRuleEmptyField(product.generico) ? null : product.generico;
        },
        clearForm() {
            this.eBackTableList();
        },
        async refreshLaboratories() {
            try {
                const response = await Product.laboratoriosMigrate();
                this.laboratoriesList = response;
            } catch(error) {
                console.log(error);
                alert('error en los datos');
            }
        }
    },
    mounted() {
        this.getRequest();
    }
}
</script>