<template>
    <v-dialog
        persistent
        width="500"
        :value="value"
    >
        <v-card>
            <div class="s-card-client__header pl-2 pr-2 d-flex align-center">
                <h1 class="app-title">
                    AÑADIR INVENTARIO
                </h1>
                <v-spacer></v-spacer>
                <OBtnCardComp
                    icon="mdi-close"
                    @click="$emit('clickclose')"
                />
            </div>
            <div class="client__line-header"></div>
            <v-form
                v-model="valid"
                lazy-validation
                ref="forminventory"
                class="pa-3"
            >
                <MTextFieldComp
                    label="Cantidad"
                    v-model="dataLot.quantity"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'La cantidad es requerida',
                        value => this.mRuleNumberInteger(value) || 'La cantidad debe ser un número'
                    ]"
                />
                <MTextFieldComp
                    label="Fecha expiración"
                    class="mt-2"
                    type="date"
                    v-model="dataLot.expiration_date"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'La fecha es requerida',
                    ]"
                />
            </v-form>
            <div class="text-center pb-3">
                <MBtnNormalComp
                    @click="eValidForm()"
                >AÑADIR</MBtnNormalComp>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import { OBtnCardComp } from "../components/organisms";
import {
    rulesMixin
} from "../mixins";
import {
    MTextFieldComp,
    MBtnNormalComp
} from "../components/molecules";
export default {
    mixins: [rulesMixin],
    components: {
        MTextFieldComp,
        OBtnCardComp,
        MBtnNormalComp
    },
    props: {
        value: Boolean,
        dataLot: Object
    },
    data: () => ({
        valid: true
    }),
    methods: {
        eValidForm() {
            if (this.$refs.forminventory.validate())
                this.$emit('saveform', true);
            else this.$emit('saveform', false);
        },
        resetForm() {
            this.$refs.forminventory.resetValidation();
        },
    }
}
</script>